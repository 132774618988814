import React, {Suspense, useState} from 'react'
import { Outlet } from "react-router-dom";
import Sidebar from '../components/sidebar';
import Header from '../components/header'
import ErrorBoundary from '../components/error-boundary'

function MainLayout() {
    const [searchTerm, setSearchTerm] = useState('');
  
    return (
      <div className="relative font-work-sans">
        <Sidebar />
        <Header onSearch={setSearchTerm} />
        <Suspense fallback={<div>Loading...</div>}>
          <ErrorBoundary>
            <Outlet context={{ searchTerm }} />
          </ErrorBoundary>
        </Suspense>
      </div>
    );
  }

export default MainLayout;